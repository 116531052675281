"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubTypeUtils = void 0;
var constants_1 = require("../../constants");
var SubTypeUtils = /** @class */ (function () {
    function SubTypeUtils() {
    }
    SubTypeUtils.isTplHeader = function (subType) {
        if (subType === void 0) { subType = ''; }
        return subType === constants_1.SubTypes.WithTL;
    };
    SubTypeUtils.isTplListBlock = function (subType) {
        if (subType === void 0) { subType = ''; }
        return subType === constants_1.SubTypes.TemplatedListBlock;
    };
    return SubTypeUtils;
}());
exports.SubTypeUtils = SubTypeUtils;
