"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTextStyle = exports.setBlockAlignText = exports.getTextAlignClassName = exports.getClassName = void 0;
var common_1 = require("../../../utils/common");
var constants_1 = require("../../../constants");
var classNameIds = {
    justify: constants_1.TextAlign.Justify,
    'justify-center': constants_1.TextAlign.Center,
    'justify-left': constants_1.TextAlign.Left,
    'justify-right': constants_1.TextAlign.Right,
    position: constants_1.TextAlign.Justify,
    'position-center': constants_1.TextAlign.Center,
    'position-left': constants_1.TextAlign.Left,
    'position-right': constants_1.TextAlign.Right,
};
var classNames = {
    textBlock: ['justify-center', 'justify-left', 'justify-right'],
    listBlock: ['position', 'position-center', 'position-left', 'position-right'],
};
function getClassName(id, node) {
    var cnId = constants_1.TextAlign.Justify;
    if (!node) {
        return cnId;
    }
    for (var i = 0; i < classNames[id].length; i++) {
        var cn = classNames[id][i];
        if (node.classList.contains(cn)) {
            cnId = classNameIds[cn];
            break;
        }
    }
    return cnId;
}
exports.getClassName = getClassName;
/*
 класс для стилей text-align:
  1) в текстовые блоки (block--text) и ячейки таблицы (block--table-cell) добавляется обертка с классом justify-right/left/center
  2) в списки (block--list) тегу ul ставятся классы position/-right/-left/-center (для justify)
 */
function getTextAlignClassName(tPar) {
    var textBlock = common_1.GetUtils.getParentElement(tPar, common_1.TypeUtils.isBlockText) ||
        common_1.GetUtils.getParentElement(tPar, common_1.TypeUtils.isBlockTableCell);
    if (textBlock === null || textBlock === void 0 ? void 0 : textBlock.classList.contains(constants_1.CLASS_NAMES.header2Block)) {
        return null;
    }
    var textStyleNode = textBlock === null || textBlock === void 0 ? void 0 : textBlock.querySelector(constants_1.CLASSES.textStyle);
    if (textBlock) {
        return getClassName('textBlock', textStyleNode);
    }
    var listBlock = common_1.GetUtils.getParentElement(tPar, common_1.TypeUtils.isWrappableList);
    if (listBlock) {
        return getClassName('listBlock', listBlock);
    }
    return null;
}
exports.getTextAlignClassName = getTextAlignClassName;
function setBlockAlignText(cnId) {
    var baseNode = common_1.GetUtils.getBaseNode();
    var node = common_1.GetUtils.getParentElement(baseNode, common_1.CheckUtils.isWrappable);
    var style = constants_1.TEXT_ALIGN_STYLES[cnId];
    if (!node) {
        return;
    }
    if (common_1.TypeUtils.isWrappableList(node)) {
        node.setAttribute('class', "nested ".concat(style.position));
    }
    if (common_1.TypeUtils.isWrappableBlock(node) || common_1.TypeUtils.isBlockTableCell(node)) {
        var firstNode = node.childNodes[0];
        if (!common_1.TypeUtils.isHtmlElement(firstNode)) {
            return;
        }
        // параграфы в div со стилями форматирования текста
        if (common_1.CheckUtils.hasClassName(firstNode, constants_1.CLASS_NAMES.textStyle)) {
            if (style.cn === 'justify') {
                // если выбирается форматирование по-умолчанию, div-обертка удаляется
                node.append.apply(node, Array.from(firstNode.childNodes));
                firstNode.remove();
            }
            else {
                firstNode.setAttribute('classList', '');
                firstNode.classList.add(constants_1.CLASS_NAMES.textStyle, style.cn);
                firstNode.style['text-align'] = style.value || undefined;
            }
        }
        else {
            var wrapper = document.createElement('div');
            wrapper.classList.add(constants_1.CLASS_NAMES.textStyle, style.cn);
            wrapper.style['text-align'] = style.value || undefined;
            wrapper.append.apply(wrapper, Array.from(node.childNodes));
            node.append(wrapper);
        }
    }
}
exports.setBlockAlignText = setBlockAlignText;
function isTextStyle(cnId, node) {
    if (!node) {
        return false;
    }
    var style = constants_1.NODE_STYLE[cnId];
    if (common_1.TypeUtils.isElement(node) && common_1.CheckUtils.hasClassName(node, style.class)) {
        return true;
    }
    var wrappableBlock = common_1.GetUtils.getParentElement(node, common_1.CheckUtils.isWrappable);
    return !!common_1.GetUtils.getParentElement(node, function (node) { return common_1.CheckUtils.isTagName(node, style.tagName); }, wrappableBlock);
}
exports.isTextStyle = isTextStyle;
