"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParsingUtils = void 0;
var ParsingUtils = /** @class */ (function () {
    function ParsingUtils() {
    }
    ParsingUtils.getOrder = function (value) {
        if (typeof value === 'string') {
            return parseInt(value.split('_')[1], 10);
        }
        return value;
    };
    return ParsingUtils;
}());
exports.ParsingUtils = ParsingUtils;
