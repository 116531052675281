"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlockTypeUtils = void 0;
var BlockTypeUtils = /** @class */ (function () {
    function BlockTypeUtils() {
    }
    BlockTypeUtils.isSection = function (type) {
        if (type === void 0) { type = ''; }
        return type === 'section';
    };
    BlockTypeUtils.isTable = function (type) {
        if (type === void 0) { type = ''; }
        return type === 'table';
    };
    BlockTypeUtils.isList = function (type) {
        if (type === void 0) { type = ''; }
        return type === 'list';
    };
    BlockTypeUtils.isHeader2 = function (type) {
        if (type === void 0) { type = ''; }
        return type === 'header2';
    };
    BlockTypeUtils.isAuthority = function (type) {
        if (type === void 0) { type = ''; }
        return type === 'authority';
    };
    return BlockTypeUtils;
}());
exports.BlockTypeUtils = BlockTypeUtils;
