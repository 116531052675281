"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubTypes = exports.COPY_NODES_ID = exports.COPY_BLOCKS_ID = exports.COPY_START_ID = exports.ZWNJ = exports.U_ZWNJ = void 0;
__exportStar(require("./general"), exports);
__exportStar(require("./boundary"), exports);
__exportStar(require("./general"), exports);
exports.U_ZWNJ = '\u200c';
exports.ZWNJ = '&zwnj;';
exports.COPY_START_ID = '#__COPY';
exports.COPY_BLOCKS_ID = '#__COPY__#';
exports.COPY_NODES_ID = '#__COPY_SELECTED_NODES__#';
var SubTypes;
(function (SubTypes) {
    SubTypes["TemplatedListBlock"] = "templatedListBlock";
    SubTypes["WithTL"] = "withTL";
})(SubTypes = exports.SubTypes || (exports.SubTypes = {}));
