"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isDisableStyleButton = exports.getAvailableBlockIds = exports.getAvailableBlocks = exports.getDepthLeftAction = exports.getDepthRightAction = exports.getAddAction = exports.getSelectionBlockProps = exports.togglePlaceholdersStyle = exports.isValidBoundarySelection = exports.canCreateBoundary = void 0;
var common_1 = require("../../../utils/common");
var BlockTypes_1 = require("../BlockTypes");
var constants_1 = require("../../../constants");
function canCreateBoundary(entityType) {
    return (!common_1.EntityUtils.isAttachmentType(entityType) &&
        !common_1.EntityUtils.isEntityTypeAuthority(entityType) &&
        !common_1.EntityUtils.isCourtDocumentEntity(entityType) &&
        !common_1.EntityUtils.isTemplatedList(entityType));
}
exports.canCreateBoundary = canCreateBoundary;
function isValidBoundarySelection() {
    var selection = window.getSelection();
    if (!selection || !selection.toString().length) {
        return false;
    }
    var hasPlaceholder = common_1.GetUtils.getParentElement(selection.anchorNode, common_1.CheckUtils.isPlaceholder) ||
        common_1.GetUtils.getParentElement(selection.focusNode, common_1.CheckUtils.isPlaceholder);
    return !hasPlaceholder;
}
exports.isValidBoundarySelection = isValidBoundarySelection;
function togglePlaceholdersStyle(styleName, selectedNodes, selectedPlaceholder) {
    if (selectedPlaceholder && selectedPlaceholder.subType !== 'boundary') {
        var node = common_1.DomUtils.selectPlaceholder(selectedPlaceholder.id);
        node === null || node === void 0 ? void 0 : node.classList.toggle(styleName);
    }
    else {
        selectedNodes.forEach(function (node) {
            if (common_1.TypeUtils.isElement(node) && common_1.CheckUtils.isPlaceholder(node)) {
                node === null || node === void 0 ? void 0 : node.classList.toggle(styleName);
            }
        });
    }
}
exports.togglePlaceholdersStyle = togglePlaceholdersStyle;
var getSelectionBlockProps = function (props) {
    var _a, _b, _c, _d, _e, _f, _g;
    return {
        autonumeric: (_a = props === null || props === void 0 ? void 0 : props.autonumeric) !== null && _a !== void 0 ? _a : false,
        blockType: (_b = props === null || props === void 0 ? void 0 : props.type) !== null && _b !== void 0 ? _b : '',
        emptyValue: (_c = props === null || props === void 0 ? void 0 : props.emptyValue) !== null && _c !== void 0 ? _c : false,
        isLib: (_d = props === null || props === void 0 ? void 0 : props.isLib) !== null && _d !== void 0 ? _d : false,
        isNumeric: ((_e = props === null || props === void 0 ? void 0 : props.content) === null || _e === void 0 ? void 0 : _e.string) === 'numeric',
        isSection: (_f = !!(props === null || props === void 0 ? void 0 : props.sectionId)) !== null && _f !== void 0 ? _f : false,
        numeration_depth: (_g = props === null || props === void 0 ? void 0 : props.numeration_depth) !== null && _g !== void 0 ? _g : 0,
    };
};
exports.getSelectionBlockProps = getSelectionBlockProps;
var getAddAction = function (isLib, isSection) {
    if (isLib) {
        return 'add-after-library';
    }
    return isSection ? 'add-section' : 'add';
};
exports.getAddAction = getAddAction;
var getDepthRightAction = function (blockType) { return (blockType === 'list' ? 'listShiftRight' : 'increaseDepth'); };
exports.getDepthRightAction = getDepthRightAction;
var getDepthLeftAction = function (blockType) { return (blockType === 'list' ? 'listShiftLeft' : 'decreaseDepth'); };
exports.getDepthLeftAction = getDepthLeftAction;
function getAvailableBlocks(type) {
    switch (type) {
        case BlockTypes_1.blockTypes.text.type:
        case BlockTypes_1.blockTypes.header2.type:
        case BlockTypes_1.blockTypes.list.type: {
            return {
                text: BlockTypes_1.blockTypes.text,
                header2: BlockTypes_1.blockTypes.header2,
                list: BlockTypes_1.blockTypes.list,
            };
        }
        // image - ?
        case BlockTypes_1.blockTypes.textLeft.type:
        case BlockTypes_1.blockTypes.textRight.type:
        case BlockTypes_1.blockTypes.image.type: {
            return {
                textLeft: BlockTypes_1.blockTypes.textLeft,
                image: BlockTypes_1.blockTypes.image,
                textRight: BlockTypes_1.blockTypes.textRight,
            };
        }
        case BlockTypes_1.blockTypes.table.type:
        case BlockTypes_1.blockTypes.files.type:
        case BlockTypes_1.blockTypes.section.type:
        case BlockTypes_1.blockTypes.attachment.type: {
            return {};
        }
        default: {
            return BlockTypes_1.blockTypes;
        }
    }
}
exports.getAvailableBlocks = getAvailableBlocks;
function getAvailableBlockIds(type, isSection) {
    if (isSection === void 0) { isSection = false; }
    if (!type) {
        return new Set();
    }
    var values = Object.keys(getAvailableBlocks(type))
        // запрещаем вставлять секцию в секцию
        .filter(function (blockType) { return !(blockType === BlockTypes_1.blockTypes.section.type && isSection); })
        // разрешаем вставлять attachment только в текстовые блоки
        .filter(function (blockType) { return !(type !== BlockTypes_1.blockTypes.text.type && blockType === BlockTypes_1.blockTypes.attachment.type); })
        // разрешаем вставлять authority только в текстовые блоки
        .filter(function (blockType) { return !(type !== BlockTypes_1.blockTypes.text.type && blockType === BlockTypes_1.blockTypes.authority.type); })
        // запрещаем действия с текстом для блока pageBreak
        .filter(function (blockType) { return blockType !== BlockTypes_1.blockTypes.pageBreak.type; });
    return new Set(values);
}
exports.getAvailableBlockIds = getAvailableBlockIds;
function isDisableStyleButton(style, blockType) {
    return style === constants_1.NodeStyles.Bold && blockType === BlockTypes_1.blockTypes.header2.type;
}
exports.isDisableStyleButton = isDisableStyleButton;
