"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExplorerTypes = exports.moduleName = void 0;
exports.moduleName = 'explorer';
var ExplorerTypes;
(function (ExplorerTypes) {
    ExplorerTypes["GET_DATA"] = "@explorer/GET_DATA";
    ExplorerTypes["GET_FOLDER_DATA_FAILED"] = "@explorer/GET_FOLDER_DATA_FAILED";
    ExplorerTypes["GET_FOLDER_LIST_SUCCESS"] = "@explorer/GET_FOLDER_LIST_SUCCESS";
    ExplorerTypes["GET_FOLDER_LIST_FAILED"] = "@explorer/GET_FOLDER_LIST_FAILED";
    ExplorerTypes["GET_KNOWN_FOLDERS_SUCCESS"] = "@explorer/GET_KNOWN_FOLDERS_SUCCESS";
    ExplorerTypes["GET_KNOWN_FOLDERS_FAILED"] = "@explorer/GET_KNOWN_FOLDERS_FAILED";
    ExplorerTypes["GET_DOCUMENTS_DATA_START"] = "@explorer/GET_DOCUMENTS_DATA_START";
    ExplorerTypes["GET_DOCUMENTS_DATA_SUCCESS"] = "@explorer/GET_DOCUMENTS_DATA_SUCCESS";
    ExplorerTypes["GET_DOCUMENTS_DATA_FAILED"] = "@explorer/GET_DOCUMENTS_DATA_FAILED";
    ExplorerTypes["SET_EXPANDED_KEYS"] = "@explorer/SET_EXPANDED_KEYS";
    ExplorerTypes["SET_MOVE_MODAL_EXPANDED_KEYS"] = "@explorer/SET_MOVE_MODAL_EXPANDED_KEYS";
    ExplorerTypes["SET_MOVE_MODAL_SELECTED_KEY"] = "@explorer/SET_MOVE_MODAL_SELECTED_KEY";
    ExplorerTypes["SET_SINGLE_ENTITY_ACTION"] = "@explorer/SET_SINGLE_ENTITY_ACTION";
    ExplorerTypes["SET_FOLDER_CATEGORIES"] = "@explorer/SET_FOLDER_CATEGORIES";
    ExplorerTypes["SET_SORT_OPTIONS"] = "@explorer/SET_SORT_OPTIONS";
    ExplorerTypes["SET_FILTER_OPTIONS"] = "@explorer/SET_FILTER_OPTIONS";
    ExplorerTypes["SET_FILTERS_VALUES"] = "@explorer/SET_FILTERS_VALUES";
    ExplorerTypes["SET_FETCHING"] = "@explorer/SET_FETCHING";
    ExplorerTypes["SELECT_FOLDER_SUCCESS"] = "@explorer/SELECT_FOLDER_SUCCESS";
    ExplorerTypes["EDIT_FOLDER"] = "@explorer/EDIT_FOLDER";
    ExplorerTypes["BULK_ENTITY"] = "@explorer/BULK_ENTITY";
    ExplorerTypes["CLEAR_BULKED_ENTITIES"] = "@explorer/CLEAR_BULKED_ENTITIES";
    ExplorerTypes["FILTER_BULKED_ENTITIES"] = "@explorer/FILTER_BULKED_ENTITIES";
    ExplorerTypes["TOGGLE_MODAL"] = "@explorer/TOGGLE_MODAL";
    ExplorerTypes["TOGGLE_SHOW_ARCHIVE"] = "@explorer/TOGGLE_SHOW_ARCHIVE";
    ExplorerTypes["SEARCH_START"] = "@explorer/START_SEARCHING";
    ExplorerTypes["SEARCH_SUCCESS"] = "@explorer/SEARCH_SUCCESS";
    ExplorerTypes["SEARCH_FAILED"] = "@explorer/SEARCH_FAILED";
})(ExplorerTypes = exports.ExplorerTypes || (exports.ExplorerTypes = {}));
