"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.clearEmptyBlocksArtefacts = exports.domParser = exports.contentAllign = exports.isDisabledCopyBlocks = exports.extractTemplatedListBlocks = exports.extractBlocks = void 0;
var lodash_1 = require("lodash");
var BlockModel_1 = __importStar(require("../components/TemplateRender/Blocks/BlockModel"));
var BlockTypes_1 = require("../components/TemplateRender/BlockTypes");
var sortFunc_1 = require("./sortFunc");
var checkJsonString_1 = require("./checkJsonString");
var V1TemplateElementModels_1 = require("../../Root/utils/api/models/TemplateElement/V1TemplateElementModels");
// [DC-4621] [DC-5075]
function extractBlocks(elementsList, isContentEditable) {
    if (isContentEditable === void 0) { isContentEditable = false; }
    var blocks = [];
    var templateElements = elementsList.templateElements;
    // блок - любой блок без parentElement_id, или блок с типом секция
    // секция - блок, у которого есть parentElement_id и type !== section
    var blocksList = templateElements === null || templateElements === void 0 ? void 0 : templateElements.filter(function (x) { return !x.fakeTemplate_id && (!x.parentElement_id || x.elementType === V1TemplateElementModels_1.TemplateElementType.Section); }).sort((0, sortFunc_1.sortByNumberField)('templateElementNumber'));
    // Относящиеся к ББ блоки
    var fakeBlocksList = templateElements === null || templateElements === void 0 ? void 0 : templateElements.filter(function (x) { return x.fakeTemplate_id && (!x.parentElement_id || x.elementType === V1TemplateElementModels_1.TemplateElementType.Section); });
    var fakeBlocksListByNumber = (0, lodash_1.groupBy)(fakeBlocksList, 'fakeTemplate_id.templateElementNumber');
    Object.keys(fakeBlocksListByNumber).forEach(function (templateElementNumber) {
        var group = fakeBlocksListByNumber[templateElementNumber].sort((0, sortFunc_1.sortByNumberField)('templateElementNumber'));
        blocksList.splice.apply(blocksList, __spreadArray([Number(templateElementNumber), 0], group, false));
    });
    var totalClonedBlocks = 0;
    blocksList === null || blocksList === void 0 ? void 0 : blocksList.forEach(function (curBlock) {
        var block = __assign({}, curBlock);
        var order = block.fakeTemplate_id ? block.fakeTemplate_id.templateElementNumber : block.templateElementNumber;
        var blockModel = new BlockModel_1.default({
            id: block.id,
            type: block.elementType,
            order: order + totalClonedBlocks,
            content: __assign(__assign({}, BlockModel_1.BlockContentModel), (block.content ? JSON.parse(block.content) : BlockModel_1.BlockContentModel)),
            sectionId: block.parentElement_id,
            name: block.name,
            sectionVariant: block.sectionVariant,
        });
        blockModel.groupName = block.group_name;
        blockModel.canBeEdited = block.canBeEdited;
        blockModel.autonumeric = block.autonumeric;
        blockModel.numeration_depth = block.numeration_depth || 0;
        blockModel.isLib = block.isLib;
        blockModel.emptyValue = block.emptyValue;
        blockModel.subType = block.subType;
        blockModel.isContentEditable = isContentEditable;
        blockModel.isReceived = block.isReceived;
        blockModel.libraryTemplate_id = block.fakeTemplate_id;
        blockModel.libraryElement_id = block.fakeElement_id;
        blockModel.template_id = block.template_id;
        blockModel.libraryElemNumber = (0, lodash_1.get)(block, 'fakeElement_id.templateElementNumber', null);
        blockModel.external_params = (0, checkJsonString_1.checkJsonString)(block.external_params)
            ? JSON.parse(block.external_params)
            : null;
        blockModel.isRequired = block.isRequired;
        blocks.push(blockModel);
    });
    blocks.sort(sortFunc_1.sortFunc);
    return blocks;
}
exports.extractBlocks = extractBlocks;
// TODO: [Mouseee] Схлопнуть в одну функцию с extractBlocks
function extractTemplatedListBlocks(templatedListElements, isContentEditable) {
    if (isContentEditable === void 0) { isContentEditable = false; }
    var blocks = [];
    var blocksList = templatedListElements === null || templatedListElements === void 0 ? void 0 : templatedListElements.filter(function (x) {
        return !x.templateElement.fakeTemplate_id &&
            (!x.templateElement.parentElement_id || typeof x.templateElement.elementType === BlockTypes_1.blockTypes.section.type);
    }).sort((0, sortFunc_1.sortByNumberField)('templateElementNumber'));
    if (blocksList) {
        blocksList = blocksList.map(function (block, idx) {
            block.templateElement.templateElementNumber = idx;
            return block;
        });
    }
    var totalClonedBlocks = 0;
    blocksList === null || blocksList === void 0 ? void 0 : blocksList.forEach(function (curBlock) {
        var templatedListBlock = {};
        var block = __assign({}, curBlock.templateElement);
        var blockModel = new BlockModel_1.default({
            id: block.id,
            type: block.elementType,
            order: curBlock.templateElement.templateElementNumber + totalClonedBlocks,
            content: __assign(__assign({}, BlockModel_1.BlockContentModel), (block.content ? JSON.parse(block.content) : BlockModel_1.BlockContentModel)),
            sectionId: block.parentElement_id,
            name: block.name,
        });
        blockModel.groupName = block.group_name;
        blockModel.canBeEdited = block.canBeEdited || false;
        blockModel.autonumeric = block.autonumeric;
        blockModel.numeration_depth = block.numeration_depth || 0;
        blockModel.isLib = block.isLib;
        blockModel.emptyValue = block.emptyValue;
        if (blockModel.type === 'section') {
            blockModel.sectionVariant = block.subType || '';
        }
        blockModel.subType = 'addTL';
        blockModel.isContentEditable = isContentEditable;
        blockModel.isReceived = block.isReceived;
        blockModel.libraryTemplate_id = block.fakeTemplate_id;
        blockModel.libraryElement_id = block.fakeElement_id;
        blockModel.template_id = block.template_id;
        blockModel.libraryElemNumber = (0, lodash_1.get)(block, 'fakeElement_id.templateElementNumber', null);
        blockModel.external_params = (0, checkJsonString_1.checkJsonString)(block.external_params)
            ? JSON.parse(block.external_params)
            : null;
        blockModel.isRequired = block.isRequired;
        blockModel.templateListId = block.template_id.id;
        templatedListBlock = {
            placeholderId: curBlock.placeholderId,
            templateElement: blockModel,
        };
        blocks.push(templatedListBlock);
    });
    blocks.sort(sortFunc_1.sortFunc);
    return blocks;
}
exports.extractTemplatedListBlocks = extractTemplatedListBlocks;
// копирование/добавление в библиотеку выбранных блоков
var isDisabledCopyBlocks = function (blocks, selectedBlockIds) {
    if (!(selectedBlockIds === null || selectedBlockIds === void 0 ? void 0 : selectedBlockIds.length)) {
        return false;
    }
    // блок с типом список полномочий должен быть всегда один, если в выбранных блоках есть блок с типом список полномочий, копировать блоки нельзя
    var authorityTypeBlock = blocks.find(function (item) { return item.type === 'authority'; });
    var canCopyAuthority = !authorityTypeBlock || !selectedBlockIds.includes(authorityTypeBlock.id);
    // если в выбранных блоках есть блок с суб типом withTL (шапка для блока с типом templatedList), копировать блоки нельзя
    var canCopyWithTL = !blocks.some(function (item) { return item.subType === 'withTL' && selectedBlockIds.includes(item.id); });
    return !canCopyAuthority || !canCopyWithTL;
};
exports.isDisabledCopyBlocks = isDisabledCopyBlocks;
/* TODO: need refactoring */
var contentAllign = function (id) {
    var _a;
    var value = (_a = document.getElementById(id)) === null || _a === void 0 ? void 0 : _a.classList.value;
    if (value === null || value === void 0 ? void 0 : value.includes('position')) {
        var trimIndex = value === null || value === void 0 ? void 0 : value.indexOf('position');
        return value === null || value === void 0 ? void 0 : value.slice(trimIndex);
    }
    return;
};
exports.contentAllign = contentAllign;
var domParser = function (node, selector) {
    return new DOMParser().parseFromString(node, 'text/html').querySelector(selector);
};
exports.domParser = domParser;
var clearEmptyBlocksArtefacts = function (deletePhsAlias, templatedListBlocks, updatedBlocks, blocks) {
    deletePhsAlias.forEach(function (ph) {
        var phDataValue = ph.dataValue;
        if (phDataValue) {
            if (templatedListBlocks === null || templatedListBlocks === void 0 ? void 0 : templatedListBlocks.length) {
                var filteredBlocksByGuid = templatedListBlocks.filter(function (block) { return block.templateElement.template_id.guid === phDataValue; });
                filteredBlocksByGuid.forEach(function (block) {
                    var _a, _b;
                    if (((_a = block.templateElement.template_id) === null || _a === void 0 ? void 0 : _a.guid) === phDataValue) {
                        var blockContentId_1 = (_b = (0, exports.domParser)(JSON.parse(block === null || block === void 0 ? void 0 : block.templateElement.content).html, '.text-paragraph')) === null || _b === void 0 ? void 0 : _b.id;
                        var blocksArtefacts_1 = [];
                        blocks.forEach(function (element) {
                            var _a;
                            var currentBlockId = (_a = (0, exports.domParser)(element === null || element === void 0 ? void 0 : element.content.html, '.text-paragraph')) === null || _a === void 0 ? void 0 : _a.id;
                            if (currentBlockId) {
                                blocksArtefacts_1.push({ currentId: element.id, currentBlockId: currentBlockId });
                            }
                        });
                        var currentElem_1 = blocksArtefacts_1.find(function (item) { return item.currentBlockId === blockContentId_1; });
                        if (currentElem_1) {
                            var finrenderedBlock = blocks === null || blocks === void 0 ? void 0 : blocks.find(function (item) { return item.id === (currentElem_1 === null || currentElem_1 === void 0 ? void 0 : currentElem_1.currentId); });
                            if (finrenderedBlock) {
                                finrenderedBlock.action = 'delete';
                            }
                        }
                    }
                });
            }
            else {
                var filteredBlocksByGuid = updatedBlocks.filter(function (block) { var _a; return (_a = block.template_id) === null || _a === void 0 ? void 0 : _a.guid; });
                filteredBlocksByGuid.forEach(function (block) {
                    var _a;
                    if (((_a = block.template_id) === null || _a === void 0 ? void 0 : _a.guid) === phDataValue) {
                        block.action = 'delete';
                    }
                });
            }
        }
    });
};
exports.clearEmptyBlocksArtefacts = clearEmptyBlocksArtefacts;
