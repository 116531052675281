"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentTypes = void 0;
var DocumentTypes;
(function (DocumentTypes) {
    DocumentTypes["GET_DATA_REQUEST"] = "@document/GET_DATA_REQUEST";
    DocumentTypes["GET_DATA_SUCCESS"] = "@document/GET_DATA_SUCCESS";
    DocumentTypes["GET_DATA_FAILURE"] = "@document/GET_DATA_FAILURE";
    DocumentTypes["SET_PAGE"] = "@document/SET_PAGE";
    DocumentTypes["SET_PAGE_SIZE"] = "@document/SET_PAGE_SIZE";
    DocumentTypes["SET_DRAFT_TEMPLATE_NAME"] = "@document/SET_DRAFT_TEMPLATE_NAME";
    DocumentTypes["SET_DRAFT_TEMPLATE_VERSION"] = "@document/SET_DRAFT_TEMPLATE_VERSION";
    DocumentTypes["SET_TEMPLATE_INFO"] = "@document/SET_TEMPLATE_INFO";
    DocumentTypes["SET_PDC_LIST"] = "@document/SET_PDC_LIST";
    DocumentTypes["SET_PDC_LIST_ADDITIONAL_INFO"] = "@document/SET_PDC_LIST_ADDITIONAL_INFO";
    DocumentTypes["SET_PDC_LIST_LOADING"] = "@document/SET_PDC_LIST_LOADING";
    DocumentTypes["SET_LOADING"] = "@document/SET_LOADING";
    DocumentTypes["SET_OBJECT_BLOCKS"] = "@document/SET_OBJECT_BLOCKS";
    DocumentTypes["SET_OBJECT_PLACEHOLDERS"] = "@document/SET_OBJECT_PLACEHOLDERS";
    DocumentTypes["SET_CHILD_PLACEHOLDERS"] = "@document/SET_CHILD_PLACEHOLDERS";
    DocumentTypes["SET_PLACEHOLDER_VALUES"] = "@document/SET_PLACEHOLDER_VALUES";
    DocumentTypes["SET_PLACEHOLDER_UPDATING"] = "@document/SET_PLACEHOLDER_UPDATING";
    DocumentTypes["SET_TEMPLATE_ID"] = "@document/SET_TEMPLATE_ID";
    DocumentTypes["SET_DOCUMENT_ID"] = "@document/SET_DOCUMENT_ID";
    DocumentTypes["SET_ELEMENT_ID"] = "@document/SET_ELEMENT_ID";
    DocumentTypes["SET_OBJECT_SECTIONS"] = "@document/SET_OBJECT_SECTIONS";
    DocumentTypes["SET_SELECTED_SECTION"] = "@document/SET_SELECTED_SECTION";
    DocumentTypes["SET_SELECTED_SECTIONS"] = "@document/SET_SELECTED_SECTIONS";
    DocumentTypes["CLEAR_STATE"] = "@document/CLEAR_STATE";
    DocumentTypes["CHANGE_PDC_REQUEST"] = "@document/CHANGE_PDC_REQUEST";
    DocumentTypes["CHANGE_PDC_SUCCESS"] = "@document/CHANGE_PDC_SUCCESS";
    DocumentTypes["CHANGE_PDC_FAILURE"] = "@document/CHANGE_PDC_FAILURE";
    DocumentTypes["SET_DIRECTORY_DICTIONARY"] = "SET_DIRECTORY_DICTIONARY";
})(DocumentTypes = exports.DocumentTypes || (exports.DocumentTypes = {}));
