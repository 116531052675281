"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetUtils = void 0;
var CheckUtils_1 = require("./CheckUtils");
var TypeUtils_1 = require("./TypeUtils");
var GetUtils = /** @class */ (function () {
    function GetUtils() {
    }
    GetUtils.getParentElement = function (element, func, wrappableBlock) {
        if (TypeUtils_1.TypeUtils.isElement(element) && func(element)) {
            return element;
        }
        if (wrappableBlock && wrappableBlock === element) {
            return null;
        }
        return TypeUtils_1.TypeUtils.isNode(element) ? GetUtils.getParentElement(element.parentNode, func, wrappableBlock) : null;
    };
    GetUtils.getSelectedNodeList = function () {
        var _a;
        var sel = window.getSelection();
        if (!sel || sel.isCollapsed) {
            return [];
        }
        return Array.from((_a = sel.getRangeAt(0).cloneContents().childNodes) !== null && _a !== void 0 ? _a : []);
    };
    GetUtils.getBaseNode = function () {
        var _a, _b;
        return (_b = (_a = window.getSelection()) === null || _a === void 0 ? void 0 : _a.anchorNode) !== null && _b !== void 0 ? _b : null;
    };
    GetUtils.getBlockNodeId = function (id) {
        var _a, _b;
        if (typeof id !== 'string') {
            return '';
        }
        var node = document.getElementById(id);
        return node ? (_b = (_a = GetUtils.getParentElement(node, CheckUtils_1.CheckUtils.isWrappable)) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : '' : '';
    };
    return GetUtils;
}());
exports.GetUtils = GetUtils;
